import { axiosInstance } from 'boot/axios'
const app_url = process.env.VUE_APP_API_URL

export function all () {
  return axiosInstance.get(`${app_url}api/conekta/cards`).then(response => {
    let cards = response.data
    return cards
  })
}

export function addCard (context, payload) {
  return axiosInstance.post(`${app_url}api/conekta/cards`, payload).then(response => {
    let card = response.data
    return card
  })
}

export function addCardIos(context, payload){
    return axiosInstance.post(`${app_url}api/conekta/cards/web`, payload ).then(response => {
        let card = response.data
        return card
    })
}
export function destroy (context, cardId) {
  return axiosInstance.delete(`${app_url}api/conekta/cards/` + cardId).then(response => {
    let card = response.data
    return card
  })
}
