import { createI18n } from 'vue-i18n'
import messages from 'src/i18n'
import app from '../main'

const i18n = createI18n({
  locale: 'en-us',
  fallbackLocale: 'en-us',
  messages
})


app.use(i18n)

export { i18n }
