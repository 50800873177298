import axios from 'axios'
import { axiosInstance } from 'boot/axios'

export function login ({ commit, dispatch }, user) {
  commit('AUTH_REQUEST')
  return axiosInstance.post('login', user)
    .then(response => {
      const token = response.data.success.token
      commit('AUTH_SUCCESS')
      dispatch('setToken', token)
      return response
    })
}

// Complete facebook login, send FB token to backend for storing
export function facebookLogin ({ commit, dispatch }, token) {
  commit('AUTH_REQUEST')
  return axiosInstance.post('/api/login/facebook', { token })
    .then(function (response) {
      const token = response.data.access_token
      commit('AUTH_SUCCESS')
      dispatch('setToken', token)
      return response
    })
}

export function logout ({ commit }) {
  window.localStorage.removeItem('token')
  commit('setToken', null)
  removeAxiosHeaders()
}

export function setToken ({ commit }, payload) {
  window.localStorage.setItem('token', payload)
  setAxiosHeaders(payload)
  commit('setToken', payload)
}

function setAxiosHeaders (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token
}
function removeAxiosHeaders () {
  delete axios.defaults.headers.common['Authorization']
  delete axiosInstance.defaults.headers.common['Authorization']
}
