import axios from 'axios'
import app from '../main'



axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded, application/json'
axios.defaults.headers['Accept'] = 'application/json'

const VUE_APP_API_URL = process.env.VUE_APP_API_URL

const axiosInstance = axios.create({
  baseURL: VUE_APP_API_URL
})

app.config.globalProperties.$axios = axiosInstance

export { axiosInstance }
