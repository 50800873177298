import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes'
import app from '../main'
import { App as CapacitorApp } from '@capacitor/app';


/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

export default function () {
  const router = createRouter({
    history: createWebHashHistory(),
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as is and change from quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    // mode: process.env.VUE_ROUTER_MODE,
    // base: process.env.VUE_ROUTER_BASE
  })

  CapacitorApp.addListener('backButton', ({canGoBack}) => {
    if(!canGoBack){
      CapacitorApp.minimizeApp();
    } else {
      window.location.back();
    }
  })
  app.use(router)
  return router

}
