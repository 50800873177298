import Vuex from 'vuex'
import auth from './auth'
// we first import the module
import basket from './basket'
import cards from './cards'
import me from './me'
import products from './products'
import createPersistedState from 'vuex-persistedstate'
import { createMigrate } from 'vuex-persistedstate-migrate'

const migrations = [
  {
    version: 1,
    up: state => {
      return {
        ...state,
        basket: {
          ...state.basket,
          myProperty: 'migrated value',
        },
        me: {
          ...state.me,
        }
      }
    }
  }
]

  const Store = new Vuex.Store({
    modules: {
      // then we reference it
      auth,
      basket,
      cards,
      products,
      me
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV,

    plugins: [createPersistedState({
      getState: createMigrate(migrations, 'migration.version'),
    })]
  })

  /*
    if we want some HMR magic for it, we handle
    the hot update like below. Notice we guard this
    code with "process.env.DEV" -- so this doesn't
    get into our production build (and it shouldn't).
  */

  if (process.env.DEV && module.hot) {
    module.hot.accept(['./basket'], () => {
      const newShowcase = require('./basket').default
      Store.hotUpdate({ modules: { showcase: newShowcase } })
    })
  }

  export default Store
