export function setToken (state, payload) {
  state.token = payload
}
// eslint-disable-next-line no-unused-vars
export function AUTH_REQUEST (state, payload) {
  state.status = 'loading'
}
// eslint-disable-next-line no-unused-vars
export function AUTH_SUCCESS (state, payload) {
  state.status = 'success'
}
